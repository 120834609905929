const skills = [
  {
    id: 1,
    title: "Game Development",
    image: "gamedev.svg",
    languages: [
      {
        id: 1,
        title: "Unity",
        css: "unity",
      },
      {
        id: 2,
        title: "C#",
        css: "csharp",
      },
      {
        id: 3,
        title: "8thwall",
        css: "eighthwall",
      },
      {
        id: 4,
        title: "THREE.js",
        css: "threejs",
      },
      {
        id: 5,
        title: "Godot",
        css: "godot",
      },
      {
        id: 5,
        title: "Shader authoring",
        css: "shaders",
      },
      {
        id: 5,
        title: "Game Design",
        css: "gamedesign",
      },
      {
        id: 2,
        title: "SparkAR",
        css: "sparkar",
      },
    ],
    description:
      "I design and develop games and interactive experiences for mobile, web, and AR/VR platforms using emerging and established technologies. I thrive on the cutting edge of what's possible in realtime interactive experiences.",
  },
  {
    id: 2,
    title: "Multimedia Asset Production",
    image: "multimedia.svg",
    languages: [
      {
        id: 2,
        title: "Blender",
        css: "blender",
      },
      {
        id: 3,
        title: "Photoshop",
        css: "photoshop",
      },
      {
        id: 4,
        title: "Substance Painter",
        css: "painter",
      },
      {
        id: 1,
        title: "Premiere Pro",
        css: "premiere",
      },
      {
        id: 1,
        title: "Figma",
        css: "figma",
      },
    ],
    description:
      "I bring my strong eye for design and traditional video disciplines to the realtime interactive realm. I create 3D models, animations, and video content for a variety of deployments.",
  },
  {
    id: 3,
    title: "Full Stack Web",
    image: "fullstack.svg",
    languages: [
      {
        id: 1,
        title: "JavaScript",
        css: "js",
      },
      {
        id: 2,
        title: "Vue.js",
        css: "vue-js",
      },
      {
        id: 3,
        title: "Express.js",
        css: "express",
      },
      {
        id: 4,
        title: "THREE.js",
        css: "threejs",
      },
      {
        id: 5,
        title: "flask",
        css: "flask",
      },
    ],
    description:
      "I build websites and web applications using modern web technologies. I use Vue.js and Express.js to build full stack applications. I also use THREE.js to create 3D realtime experiences for mobile and HMDs.",
  },
];

export default skills;
