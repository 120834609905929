const experience = [
  {
    id: 1,
    date: "2020 - 2024",
    position: "Technical Artist",
    company: "Invisible Thread LLC",
    url: "https://invisiblethread.com/",
    description: [
      {
        id: 1,
        description:
          "Built augmented reality experiences for clients using Unity, SparkAR, and 8thwall",
      },
      {
        id: 2,
        description:
          "Vetted, learned and pitched new frameworks to expand our offerings",
      },
      {
        id: 3,
        description:
          "Built and maintained internal tools and processes to streamline the development process",
      },
      {
        id: 4,
        description:
          "Mentored junior developers as well as training artists and designers for realtime asset creation",
      },
    ],
  },
  {
    id: 2,
    date: "2023",
    position: "Creative Coder (contract)",
    company: "Ben Mandeburg LLC",
    url: "",
    description: [
      {
        id: 1,
        description:
          "Using Kinectv2 and a projector running a Touchdesigner based interactive experience, we created a dynamic interactive entrance display for client SAP",
      },
    ],
  },
  {
    id: 3,
    date: "2018-2020",
    position: "Creative Coder (contract)",
    company: "All Of It Now",
    url: "https://allofitnow.com/",
    description: [
      {
        id: 1,
        description:
          "Front end development for Vuejs based trade show experiences and projection mapping installations",
      },
      {
        id: 2,
        description: "AR research using Unity and Vuforia",
      },
      {
        id: 3,
        description: "Creative director for a 3D framework benchmarking tool",
      },
    ],
  },
  {
    id: 4,
    date: "2014-2017",
    position: "Creative Coder",
    company: "Lab Binaer",
    url: "https://labbinaer.de/",
    description: [
      {
        id: 1,
        description:
          "Built THREE.js based webGL VR experiences for client Ferchau Engineering",
      },
      {
        id: 2,
        description:
          "Interactive installation and museum exhibit design and fabrication using VVVV",
      },
      {
        id: 3,
        description:
          "CAD, microcontroller programming and 3D printing for custom installations",
      },
    ],
  },
  {
    id: 5,
    date: "2013-2015",
    position: "Video Editor (contract)",
    company: "Vignette Interactive",
    url: "",
    description: [
      {
        id: 1,
        description:
          "Produced and edited video content for clients such as the Center for American Progress and Vice news",
      },
      {
        id: 2,
        description:
          "Created webby award winning interactive video documentaries",
      },
      {
        id: 3,
        description:
          "Developed motion graphics packages and explainers for clients",
      },
    ],
  },
];

export default experience;
