<template>
  <!-- Menu -->
  <MenuVue :links="links">ZMM</MenuVue>
  <HeroSection id="hero" pic="selfie3.jpg">
    <template v-slot:welcome>Hi there!</template>
    My name is <b>Zack Marlow-McCarthy</b> and I'm a
    <hi-word>Game Developer</hi-word>
    and multimedia jack of all trades. My passion is building
    <hi-word>XR experiences</hi-word> and <hi-word>Games</hi-word>, and telling
    compelling stories with new media. I'm currently looking for new opportunities so get in touch!
    <template v-slot:buttons>
      <!-- <simple-button
        class="btn-primary text-white"
        url="https://www.linkedin.com/in/zack-marlow-mccarthy-18064611/"
        icon="fab linkedin-in"
      >
        Hire me!
      </simple-button> -->
      <simple-button 
        class="btn-outline-secondary"
        url="mailto:zackmarlow@gmail.com"
        icon="fa-regular fa-file-lines"
      >
        Email me @ zackmarlow@gmail.com
      </simple-button>
    </template>
  </HeroSection>
  <SkillsGrid id="skills" :skills="skills" />
  <ExperienceTable id="edu-exp" :experience="experience" />
  <!-- <AwardsGrid id="awards" :awards="awards" /> -->
  <ProjectsGrid id="projects" :projects="projects" />
  <FooterVue :links="socials" :copyright="copyright" />
</template>
<script>
import MenuVue from "@/components/shared/AppMenu.vue";
import FooterVue from "@/components/shared/AppFooter.vue";
import HeroSection from "@/components/hero/HeroSection.vue";
import SkillsGrid from "@/components/skills/SkillsGrid.vue";
import ExperienceTable from "@/components/experience/ExperienceTable.vue";
import AwardsGrid from "@/components/awards/AwardsGrid.vue";
import ProjectsGrid from "@/components/projects/ProjectsGrid.vue";

// data to fill components
import skills from "@/data/skills";
import experience from "@/data/experience";
import awards from "@/data/awards";
import projects from "@/data/projects";

import SimpleButton from "@/components/reusable/SimpleButton.vue";
import HiWord from "@/components/reusable/HighlightIt.vue";

export default {
  name: "HomeView",
  data: () => {
    return {
      delay: 100,
      links: [
        {
          name: "home",
          url: "#hero",
        },
        {
          name: "skills",
          url: "#skills",
        },
        {
          name: "experience",
          url: "#edu-exp",
        },
        {
          name: "projects",
          url: "#projects",
        },
      ],
      skills,
      experience,
      awards,
      projects,
      socials: [
        {
          id: 1,
          icon: "fab linkedin-in",
          url: "https://www.linkedin.com/in/zack-marlow-mccarthy-18064611/",
        },
        {
          id: 2,
          icon: "fab github",
          url: "https://github.com/Marlowmz",
        },
      ],
      copyright: "2024 Zack Marlow-McCarthy",
    };
  },
  components: {
    MenuVue,
    FooterVue,
    HeroSection,
    SkillsGrid,
    ExperienceTable,
    AwardsGrid,
    ProjectsGrid,
    SimpleButton,
    HiWord,
  },
};
</script>
<style lang="scss" scoped>
.prim {
  color: $primary;
}
</style>
