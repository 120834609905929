const projects = [
  
  {
    id: 2,
    title: "Frame Hanger Pro",
    image: "framehangerpro.jpg",
    url: "https://apps.apple.com/us/app/frame-hanger-pro/id6481726124?uo=2",
    category: "XR",
    tags: ["Unity", "C#", "Blender", "Figma", "MR", "AVP"],
    description:
      "Frame Hanger Pro was built for the Apple Vision Pro as a proof of concept for mixer reality utility applications. The app allows users to hang pictures on their walls using AR and the Apple Vision Pro. The app was built using Unity and the VisionOS SDK.",
  },
  {
    id: 4,
    title: "Meta Social AR Initiative",
    image: "socialar.jpg",
    url: "https://invisiblethread.com/case-study/social-augmented-reality/",
    category: "XR",
    tags: ["SparkAR", "Javascript", "GLSL", "Blender", "Mobile", "AR"],
    description:
      "With Invisible Thread, we created over a dozen AR filters for Messenger and Instagram for client Meta, and helped to test new features drive the platform forward by creating unique and engaging experiences.",
  },
  {
    id: 3,
    title: "Portland Winter Lights Festival",
    image: "winter lights festival.jpg",
    url: "https://www.instagram.com/p/Cosg9D1DwfC/",
    category: "XR",
    tags: ["8thwall", "Javascript", "GLSL", "Niantic", "Mobile", "AR"],
    description:
      "With Invisible Thread, we created an AR scavenger hunt for the Portland Winter Lights Festival. The experience was built using 8thwall and Niantic's AR platform to create a unique experience for festival goers.",
  },{
    id: 1,
    title: "Filta",
    image: "filtacopy.jpg",
    url: "https://www.instagram.com/p/CmZ4JKwL7Kq/",
    category: "XR",
    tags: ["Unity", "C#", "Blender", "Substance Painter", "AR", "Mobile"],
    description:
      "Filta is a unique platform where users can create and trade unique filters that can then be traded as NFTs on the ethereum blockchain. At Invisible Thread, I helped create a proof of concept for the platform, which included a Unity based AR filter headdress.",
  },
  {
    id: 5,
    title: "Emerging Radiance AR",
    image: "emergingradiance.jpg",
    url: "https://invisiblethread.com/case-study/emerging-radiance/",
    category: "XR",
    tags: ["SparkAR", "Javascript", "GLSL", "Blender", "Mobile", "AR"],
    description:
      "I had the pleasure of wokring with Invisible Thread for client Meta to bring to life the stories and experiences of Japanese Americans during WWII, exploring the resiliency and hardships of their community as an Instagram filter to allow users to share.",
  },
  {
    id: 6,
    title: "Charity Water",
    image: "charitywater.jpg",
    url: "https://allofitnow.com/project/charity-water",
    category: "Interactive Installations",
    tags: ["VueJS", "Javascript", "THREEjs", "Axios", "Mobile"],
    description:
      "With All Of It Now, we created a web based experience for Charity Water to help raise awareness and donations for clean water in Africa. My work consisted of a front end iPad app that allowed attendees to donate in real time, as well as a dynamic display that showed the impact of their donations.",
  },
  {
    id: 7,
    title: "Epson DSE Digital Café Concept",
    image: "digital cafe.jpg",
    url: "https://www.youtube.com/watch?v=64c-3_XqbNE",
    category: "Interactive Installations",
    tags: [
      "VueJS",
      "Javascript",
      "THREEjs",
      "Axios",
      "Express",
      "NodeJS",
      "Mobile",
      "Projection Mapping",
    ],
    description:
      "With All Of It Now, I wrote a small lightweight full-stack framework for creating digital projection mapped signage for Epson's booth at the Digital Signage Expo as a proof of concept for a digital café experience. This included an iPad ordering interface as well as a dynamic display that showed the status of orders.",
  },
  {
    id: 8,
    title: "Carbon Fiber Visuals",
    image: "carbon_visuals.jpg",
    url: "https://vimeo.com/175691984",
    category: "Interactive Installations",
    tags: ["AfterEffects", "Fusion360", "Projection Mapping", "Exhibit Design"],
    description:
      "While working with Lab Binaer we were recruited to make a digital wallpaper for an exhibit at the Staatliches Textil und Industriemuseum Augsburg. I was responsible for creating the visuals as well as planning and setting up the projection mapping for the exhibit.",
  },
  {
    id: 9,
    title: "Nebula",
    image: "nebula.jpg",
    url: "https://vimeo.com/158182877",
    category: "Interactive Installations",
    tags: ["Processing", "Java", "Projection Mapping", "KinectV2"],
    description:
      "Working with Lab Binaer, I programmed a sensual interactive visual and musical instrument exploring depth and touch. Using a Kinect, Java and OpenGL on a stretchable canvas to create a touch surface with depth.",
  },
];

export default projects;
